import { TreeNode } from 'primereact/treenode';
import { useAuth } from './useAuth';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {
  StockManagementApi,
  Configuration as CoreClientConfig,
  StockStorageLocationDto,
  StockWarehouseDto,
  StockCategoryDto,
  StockArticleDto,
  StockArticleSummaryDto,
  BillOfMaterialsApi,
  BillOfMaterialTemplateDto,
  StockUnitTypeDto,
  ShoppingListApi,
  BillOfMaterialDto,
  ShoppingListItemDto,
  BillOfMaterialItemDto,
  BillOfMaterialAlterationDto,
} from '../api/core-client';

export const useBOM = () => {
  const { auth } = useAuth();

  const _coreConfig = useMemo(
    () =>
      new CoreClientConfig({
        basePath: process.env.REACT_APP_SERVICE_URL_CORE,
        apiKey: auth?.jwt,
      }),
    [auth?.jwt],
  );

  const _bomApi = useMemo(
    () => new BillOfMaterialsApi(_coreConfig),
    [_coreConfig],
  );

  const ListBOMTemplatesForProduct = async (productId: number) => {
    let result: BillOfMaterialTemplateDto[] = [];

    try {
      const resp = await _bomApi.billOfMaterialsGetBOMTemplatesForProduct(
        productId,
      );

      if (resp.status == 200) {
        result = resp.data;
      }
    } catch (e) {
      result = [];
    }

    return result;
  };

  const UpdateBOMTemplateForProduct = async (
    productId: number,
    bomtemplate: BillOfMaterialTemplateDto,
  ) => {
    try {
      if (
        !productId ||
        !bomtemplate ||
        !bomtemplate.templateContent ||
        !bomtemplate.name
      )
        return;

      if (!bomtemplate?.id) {
        const resp = await _bomApi.billOfMaterialsAddBOMTemplateForProduct(
          productId,
          { ...bomtemplate, id: 0 },
        );
        if (resp.status == 200) {
          return resp.data;
        }
      } else {
        const resp = await _bomApi.billOfMaterialsUpdateBOMTemplateForProduct(
          productId,
          bomtemplate,
        );
        if (resp.status == 200) {
          if (resp.data == true) {
            return bomtemplate;
          }
        }
      }
    } catch (e) {
      console.warn('Unable to upsert BOM Template', { e });
    }
    return undefined;
  };

  const GetOrderSpecificBillOfMaterials = useCallback(
    async (orderId: number) => {
      let result: BillOfMaterialDto[] = [];
      try {
        const response = await _bomApi.billOfMaterialsGetBOMForOrder(orderId);
        if (response.status == 200) {
          result = response.data;
        }
      } catch {
        result = [];
      }
      return result;
    },
    [_bomApi],
  );

  const DeleteFromBOM = useCallback(
    async (orderId: number, bomItemId: number) => {
      let result: boolean = false;

      try {
        const response = await _bomApi.billOfMaterialsDeleteBOMItem(
          orderId,
          bomItemId,
        );
        if (response.status == 200) {
          result = response.data;
        }
      } catch {
        result = false;
      }

      return result;
    },
    [_bomApi],
  );

  const AddOrUpdateItemBOM = useCallback(
    async (orderId: number, bomItemMutation: BillOfMaterialAlterationDto) => {
      if (!bomItemMutation || bomItemMutation.bomItemId === undefined)
        return false;

      let result: boolean = false;

      if ((bomItemMutation.alternativeArticleId ?? 0) == 0){
        bomItemMutation.alternativeArticleLocationId=null;
      }
      if ((bomItemMutation.alternativeArticleLocationId ?? 0) == 0) {
        bomItemMutation.alternativeArticleLocationId = null
      }

      try {
        const response = await _bomApi.billOfMaterialsProcessBOMItemMutation(
          orderId,
          bomItemMutation.bomItemId,
          bomItemMutation,
        );
        if (response.status === 200) {
          result = response.data;
        }
      } catch {
        result = false;
      }

      return result;
    },
    [_bomApi],
  );

  return {
    ListBOMTemplatesForProduct,
    UpdateBOMTemplateForProduct,
    GetOrderSpecificBillOfMaterials,
    DeleteFromBOM,
    AddOrUpdateItemBOM,
  };
};

export const useStock = () => {
  const { auth } = useAuth();

  const [categories, setCategories] = useState<StockCategoryDto[]>([]);
  const [warehouses, setWarehouses] = useState<StockWarehouseDto[]>([]);
  const [storageLocations, setStorageLocations] = useState<
    StockStorageLocationDto[]
  >([]);

  const _coreConfig = useMemo(
    () =>
      new CoreClientConfig({
        basePath: process.env.REACT_APP_SERVICE_URL_CORE,
        //basePath: 'https://localhost:44318/v2',
        apiKey: auth?.jwt,
      }),
    [auth?.jwt],
  );

  const _stockApi = useMemo(
    () => new StockManagementApi(_coreConfig),
    [_coreConfig],
  );

  const ProcessArticleInventoryMutation = useCallback(
    async (articleId: number, locationId: number, mutationAmount: number) => {
      if (mutationAmount == 0) return true;
      if (articleId <= 0 || locationId <= 0) return false;

      debugger;
      let result: boolean = false;
      try {
        const response = await _stockApi.stockManagementAddArticleMutation(articleId, { amount: mutationAmount, articleId: articleId, locationId: locationId})
        if (response.status == 200) {
          result = true;
        }
      } catch {
        result = false;
      }

      return result;
    },
    [_stockApi],
  );

  const ListUnitTypes = useCallback(async () => {
    var data: StockUnitTypeDto[] = [];

    try {
      const response = await _stockApi.stockUnittypesGet();
      if (
        response.status == 200 &&
        response.data !== undefined &&
        response.data !== null
      ) {
        data = response.data;
      }
    } catch {
      data = [];
    }

    return data;
  }, [_stockApi]);

  const AddUnitType = useCallback(
    async (data: StockUnitTypeDto) => {
      let result = false;
      try {
        let r = await _stockApi.stockUnittypesPost(data);
        result = r.status === 200 || r.status === 201;
      } catch {
        result = false;
      }

      return result;
    },
    [_stockApi],
  );

  const UpdateUnitType = useCallback(
    async (data: StockUnitTypeDto) => {
      let result = false;
      try {
        let r = await _stockApi.stockUnittypesPut(data);
        result = r.status === 200 && r.data;
      } catch {
        result = false;
      }

      return result;
    },
    [_stockApi],
  );

  const ListWarehouses = useCallback(
    async (page?: number, pageSize: number = 25) => {
      var data: StockWarehouseDto[] = [];

      try {
        const response = await _stockApi.stockManagementGetWarehouses(
          page,
          pageSize,
        );
        if (
          response.status == 200 &&
          response.data !== undefined &&
          response.data !== null
        ) {
          data = response.data;
        }
      } catch {
        data = [];
      }

      return data;
    },
    [_stockApi],
  );

  const AddWarehouse = useCallback(
    async (data: StockWarehouseDto) => {
      if (!data.name || (data.id && data.id > 0)) return;

      try {
        const response = await _stockApi.stockManagementAddNewWarehouse({
          id: 0,
          description: data.description,
          name: data.name,
        });

        if (response.status === 200) {
          let newWarehouse = response.data;
          setWarehouses((old) => [...old, newWarehouse]);
          return true;
        }
      } catch {
        return false;
      }
    },
    [_stockApi],
  );

  const UpdateWarehouse = useCallback(
    async (warehouseId: number, data: Omit<StockWarehouseDto, 'id'>) => {
      if (!data.name || !warehouseId || warehouseId <= 0) return;

      try {
        const response = await _stockApi.stockManagementUpdateWarehouseById(
          warehouseId,
          {
            id: warehouseId,
            description: data.description,
            name: data.name,
          },
        );

        if (response.status === 200) {
          if (response.data === true) {
            setWarehouses((old) =>
              old.map((s) => {
                if (s.id === warehouseId) {
                  s.name = data.name;
                  s.description = data.description;
                }
                return s;
              }),
            );
            return true;
          }
        }
      } catch {}
      return false;
    },
    [_stockApi],
  );

  const DeleteWarehouse = useCallback(
    async (
      warehouseId: number,
      force: boolean = false,
    ): Promise<[boolean, string?] | undefined> => {
      if ((warehouseId ?? 0) <= 0) return;

      try {
        let response = await _stockApi.stockManagementDeleteWarehouseById(
          warehouseId,
          force,
        );
        if (response.status === 409) {
          // no can do..
          return [false, 'E_FORCE_REQUIRED'];
        } else if (response.status === 200) {
          if (response.data === true) {
            setWarehouses((old) => old.filter((x) => x.id != warehouseId));
          }

          return [response.data];
        }
      } catch {
        return [false, 'E_GENERAL_FAILURE'];
      }
      return [false];
    },
    [_stockApi],
  );

  const ListLocationsForWarehouse = useCallback(
    async (warehouseId: number, page?: number, pageSize: number = 25) => {
      var data: StockStorageLocationDto[] = [];
      try {
        const response = await _stockApi.stockManagementGetWarehouseLocations(
          warehouseId,
          page,
          pageSize,
        );
        if (
          response.status == 200 &&
          response.data !== undefined &&
          response.data !== null
        ) {
          data = response.data;
        }
      } catch {
        data = [];
      }
      return data;
    },
    [_stockApi],
  );

  const ListCategories = useCallback(async () => {
    var data: StockCategoryDto[] = [];

    try {
      const response = await _stockApi.stockManagementGetCategories();
      if (
        response.status == 200 &&
        response.data !== undefined &&
        response.data !== null
      ) {
        data = response.data;
      }
    } catch {
      data = [];
    }
    return data;
  }, [_stockApi]);

  const AddCategory = useCallback(
    async (catData: Omit<StockCategoryDto, 'id'>) => {
      if (!catData.name) return false;

      try {
        const response = await _stockApi.stockManagementAddNewCategory({
          id: 0,
          parentCategoryId: catData.parentCategoryId,
          description: catData.description,
          name: catData.name,
        });

        if (response.status === 200) {
          return response.data;
        }
      } catch {}
      return false;
    },
    [_stockApi],
  );

  const UpdateCategory = useCallback(
    async (id: number, catData: Omit<StockCategoryDto, 'id'>) => {
      if (id <= 0 || !catData.name) return false;

      try {
        const response = await _stockApi.stockManagementUpdateCategoryById(id, {
          id: id,
          parentCategoryId: catData.parentCategoryId,
          description: catData.description,
          name: catData.name,
        });

        if (response.status === 200) {
          return response.data;
        }
      } catch {}
      return false;
    },
    [_stockApi],
  );

  const DeleteCategory = useCallback(
    async (id: number) => {
      if (id <= 0) return false;

      try {
        const response = await _stockApi.stockManagementDeleteCategoryById(id);
        if (response.status == 200) {
          return response.data;
        }
      } catch {}

      return false;
    },
    [_stockApi],
  );

  const RefreshCategories = useCallback(async () => {
    try {
      const r = await _stockApi.stockManagementGetCategories();
      if (r.status == 200) {
        setCategories(r.data);
      }
    } catch {}
  }, [_stockApi]);

  const GetAvailableArticleLocations = useCallback(
    async (articleId?: number) => {
      if (!articleId) return [];
      if (articleId <= 0) return [];
      let result: (StockStorageLocationDto & { amount?: number })[] = [];

      try {
        const artLocResponse =
          await _stockApi.stockManagementGetArticleLocations(articleId);
        if (artLocResponse.status === 200) {
          for (const al of artLocResponse.data) {
            const found = storageLocations.find((p) => p.id == al.locationId);
            if (found) {
              result.push({ ...found, amount: al.amount });
            }
          }
        }
      } catch {
        result = [];
      }

      return result;
    },
    [_stockApi, storageLocations],
  );

  const ListArticlesForCategoryId = useCallback(
    async (categoryId?: number, fullDetails?: boolean) => {
      var data: (StockArticleDto | StockArticleSummaryDto)[] = [];

      if (categoryId && categoryId <= 0) return [];

      try {
        const response = await _stockApi.stockManagementGetArticles(
          categoryId,
          fullDetails,
        );
        if (
          response.status == 200 &&
          response.data !== undefined &&
          response.data !== null
        ) {
          data = response.data;
        }
      } catch {
        data = [];
      }
      return data;
    },
    [_stockApi],
  );

  const ListArticlesOnLocation = useCallback(
    async (warehouseId: number, locationId: number) => {
      let data: StockArticleDto[] = [];
      if (!warehouseId || warehouseId <= 0 || !locationId || locationId <= 0)
        return [];

      try {
        const response =
          await _stockApi.stockManagementGetWarehouseLocationArticles(
            warehouseId,
            locationId,
          );
        if (
          response.status == 200 &&
          response.data !== undefined &&
          response.data !== null
        ) {
          data = response.data;
        }
      } catch {
        data = [];
      }

      return data;
    },
    [_stockApi],
  );

  const AddLocation = useCallback(
    async (data: StockStorageLocationDto) => {
      if (!data.name || !data.warehouseId || (data.id && data.id > 0)) return;

      try {
        const response = await _stockApi.stockManagementAddWarehouseLocations(
          data.warehouseId,
          {
            id: 0,
            warehouseId: data.warehouseId,
            locationType: data.locationType,
            name: data.name,
          },
        );

        if (response.status == 200) return response.data;
      } catch {
        return false;
      }
      return false;
    },
    [_stockApi],
  );

  const UpdateLocation = useCallback(
    async (locationId: number, data: Omit<StockStorageLocationDto, 'id'>) => {
      if (!data.name || !data.warehouseId || !locationId || locationId <= 0)
        return;

      try {
        const response =
          await _stockApi.stockManagementUpdateWarehouseLocationDetails(
            data.warehouseId,
            locationId,
            {
              id: locationId,
              locationType: data.locationType,
              warehouseId: data.warehouseId,
              name: data.name,
            },
          );

        if (response.status === 200) {
          return response.data;
        }
      } catch {}
      return false;
    },
    [_stockApi],
  );

  const DeleteLocation = useCallback(
    async (
      warehouseId: number,
      locationId: number,
      force: boolean = false,
    ): Promise<[boolean, string?] | undefined> => {
      if ((warehouseId ?? 0) <= 0 || (locationId ?? 0) <= 0) return;

      try {
        let response =
          await _stockApi.stockManagementDeleteWarehouseLocationById(
            warehouseId,
            locationId,
            force,
          );
        if (response.status === 409) {
          // no can do..
          return [false, 'E_FORCE_REQUIRED'];
        } else if (response.status === 200) {
          return [response.data];
        }
      } catch {
        return [false, 'E_GENERAL_FAILURE'];
      }
      return [false];
    },
    [_stockApi],
  );

  const DeleteArticle = useCallback(
    async (articleId: number) => {
      let result: boolean = false;
      try {
        const response = await _stockApi.stockManagementDeleteArticleById(
          articleId,
        );
        if (response.status === 200) {
          result = response.data;
        }
      } catch {
        result = false;
      }
      return result;
    },
    [_stockApi],
  );

  const AddOrUpdateArticle = useCallback(
    async (article: StockArticleDto): Promise<boolean> => {
      let result: boolean = false;

      try {
        if (article.id == 0 || article.id == undefined) {
          let addResponse = await _stockApi.stockManagementAddNewArticle(
            article,
          );
          if (addResponse.status == 200) {
            result = addResponse.data;
          }
        } else {
          let updateResponse = await _stockApi.stockManagementUpdateArticleById(
            article.id,
            article,
          );
          if (updateResponse.status == 200) {
            result = updateResponse.data;
          }
        }
      } catch {
        result = false;
      }
      return result;
    },
    [_stockApi],
  );

  useEffect(() => {
    if (_stockApi) {
      Promise.all([
        _stockApi.stockManagementGetCategories(),
        _stockApi.stockManagementGetWarehouses(),
      ]).then(([respCat, respWH]) => {
        if (respCat.status === 200) {
          setCategories(respCat.data);
        }

        if (respWH.status == 200) {
          setWarehouses(respWH.data);
        }
      });
    }
  }, [_stockApi]);

  useEffect(() => {
    setStorageLocations([]);
    
    for (let w of warehouses) {
      if (!w.id) continue;
      _stockApi.stockManagementGetWarehouseLocations(w.id!).then((x) =>
        setStorageLocations((old) => {
          let n = x.data.filter(y=>!old.find(z=>z.id == y.id))
          old.push(...n);
          return old;
        }),
      );
    }
  }, [warehouses]);

  const categoryTree = useMemo(() => {
    if (!categories || Object.keys(categories).length == 0) return;

    let treemap: Record<number, TreeNode> = {};
    for (const c of Object.values(categories)) {
      if (!treemap[c.id!])
        treemap[c.id!] = {
          key: c.id?.toString(),
          id: c.id?.toString(),
          label: c.name,
          data: c,
          children: [],
        };
    }

    let cattree: TreeNode[] = [];
    for (const c of Object.values(categories)) {
      const n = treemap[c.id!];
      if (c.parentCategoryId && treemap[c.parentCategoryId]) {
        treemap[c.parentCategoryId].children?.push(n);
      } else {
        cattree.push(n);
      }
    }

    return cattree;
  }, [categories]);

  const GetArticleDetails = useCallback(
    async (articleId: number) => {
      let result: StockArticleDto | undefined = undefined;
      try {
        const detailsResponse =
          await _stockApi.stockManagementGetArticleDetailsById(articleId);
        if (detailsResponse.status == 200) {
          result = detailsResponse.data;
        }
      } catch {
        result = undefined;
      }

      return result;
    },
    [_stockApi],
  );

  return {
    ProcessArticleInventoryMutation,
    ListArticlesForCategoryId,
    ListArticlesOnLocation,
    ListWarehouses,
    AddWarehouse,
    UpdateWarehouse,
    DeleteWarehouse,
    ListLocationsForWarehouse,
    AddLocation,
    UpdateLocation,
    DeleteLocation,
    ListCategories,
    AddCategory,
    UpdateCategory,
    DeleteCategory,
    GetAvailableArticleLocations,

    ListUnitTypes,
    AddUnitType,
    UpdateUnitType,

    AddOrUpdateArticle,
    GetArticleDetails,
    DeleteArticle,

    categories,
    setCategories,
    refreshCategories: RefreshCategories,
    warehouses,
    storageLocations,
    categoryTree,
  };
};

export const useShoppingList = () => {
  const { auth } = useAuth();

  const _coreConfig = useMemo(
    () =>
      new CoreClientConfig({
        basePath: process.env.REACT_APP_SERVICE_URL_CORE,
        apiKey: auth?.jwt,
      }),
    [auth?.jwt],
  );

  const _shoppinglistApi = useMemo(
    () => new ShoppingListApi(_coreConfig),
    [_coreConfig],
  );

  const GetShoppingListItems = useCallback(async () => {
    const response = await _shoppinglistApi.shoppingListListShoppingListItems();
    if (response.status == 200) {
      return response.data;
    }
    return [];
  }, [_shoppinglistApi]);

  const DeleteShoppingListItem = useCallback(
    async (itemId: number) => {
      let result: boolean = false;

      try {
        const response =
          await _shoppinglistApi.shoppingListDeleteShoppingListItem(itemId);
        if (response.status == 200) {
          result = response.data;
        } else if (response.status == 204) {
          result = true;
        }
      } catch {
        result = false;
      }
      return result;
    },
    [_shoppinglistApi],
  );

  const AddShoppingListItem = useCallback(
    async (values: ShoppingListItemDto) => {
      let result: boolean = false;
      const resp = await _shoppinglistApi.shoppingListAddShoppingListItem(
        values,
      );

      if (resp.status == 200 || resp.status == 201) {
        result = true;
      } else {
        result = false;
      }

      return result;
    },
    [_shoppinglistApi],
  );

  const UpdateShoppingListItem = useCallback(
    async (itemId: number, values: ShoppingListItemDto) => {
      let result: boolean = false;

      try {
        const resp = await _shoppinglistApi.shoppingListUpdateShoppingListItem(
          itemId,
          values,
        );
        if (resp.status == 200) {
          result = resp.data;
        }
      } catch {
        result = false;
      }

      return result;
    },
    [_shoppinglistApi],
  );

  return {
    GetShoppingListItems,
    DeleteShoppingListItem,
    AddShoppingListItem,
    UpdateShoppingListItem,
  };
};
