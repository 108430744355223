/* tslint:disable */
/* eslint-disable */
/**
 * Order API
 * Facilitates the creation, modification, and querying of \"orders\"
 *
 * The version of the OpenAPI document: 3.0.3
 * Contact: contact@weijbv.nl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ActionResultBody
 */
export interface ActionResultBody {
    /**
     * 
     * @type {boolean}
     * @memberof ActionResultBody
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof ActionResultBody
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface AddDeliveryProposalBody
 */
export interface AddDeliveryProposalBody {
    /**
     * 
     * @type {Date}
     * @memberof AddDeliveryProposalBody
     */
    'proposedDate'?: Date;
    /**
     * 
     * @type {Array<number>}
     * @memberof AddDeliveryProposalBody
     */
    'orderIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface AppointmentEvent
 */
export interface AppointmentEvent {
    /**
     * 
     * @type {number}
     * @memberof AppointmentEvent
     */
    'id'?: number;
    /**
     * determines the type of appointment, e.g. DELIVERY, MEASURING, CALL, etc...
     * @type {string}
     * @memberof AppointmentEvent
     */
    'appointmentType'?: string;
}
/**
 * 
 * @export
 * @interface Approval
 */
export interface Approval {
    /**
     * 
     * @type {string}
     * @memberof Approval
     */
    'formId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Approval
     */
    'motivation'?: string;
}
/**
 * 
 * @export
 * @interface AssignTo
 */
export interface AssignTo {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignTo
     */
    'username'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CalendarEvent
 */
export interface CalendarEvent {
    /**
     * 
     * @type {number}
     * @memberof CalendarEvent
     */
    'transportId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    'calendarId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    'deliveryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    'extEventId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarEvent
     */
    'orderId'?: number;
}
/**
 * 
 * @export
 * @interface CalendarsInner
 */
export interface CalendarsInner {
    /**
     * 
     * @type {string}
     * @memberof CalendarsInner
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarsInner
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ChangeLogItem
 */
export interface ChangeLogItem {
    /**
     * 
     * @type {number}
     * @memberof ChangeLogItem
     */
    'changedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogItem
     */
    'changedBy'?: string;
}
/**
 * 
 * @export
 * @interface CopyOrder200Response
 */
export interface CopyOrder200Response {
    /**
     * 
     * @type {number}
     * @memberof CopyOrder200Response
     */
    'orderId'?: number;
}
/**
 * 
 * @export
 * @interface CreateInvoiceBody
 */
export interface CreateInvoiceBody {
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateInvoiceBody
     */
    'orderIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateInvoiceBody
     */
    'extCustomerId'?: string;
}
/**
 * 
 * @export
 * @interface CreatedResponse
 */
export interface CreatedResponse {
    /**
     * 
     * @type {string}
     * @memberof CreatedResponse
     */
    'orderId'?: string;
}
/**
 * 
 * @export
 * @interface DeliveryProposal
 */
export interface DeliveryProposal {
    /**
     * 
     * @type {number}
     * @memberof DeliveryProposal
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryProposal
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryProposal
     */
    'customerId': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryProposal
     */
    'proposalHash'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryProposal
     */
    'proposedDate': Date;
    /**
     * 
     * @type {string}
     * @memberof DeliveryProposal
     */
    'proposalSentDate'?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryProposal
     */
    'proposalReminderSentDate'?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryProposal
     */
    'proposalAccepted'?: boolean | null;
    /**
     * 
     * @type {Date | null}
     * @memberof DeliveryProposal
     */
    'proposalResponseDate'?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryProposal
     */
    'proposalResponseTimeFrame'?: string | null;
}
/**
 * detailed work stats
 * @export
 * @interface Detailedworkstats
 */
export interface Detailedworkstats {
    /**
     * 
     * @type {string}
     * @memberof Detailedworkstats
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Detailedworkstats
     */
    'week'?: number;
    /**
     * 
     * @type {number}
     * @memberof Detailedworkstats
     */
    'day'?: number;
    /**
     * 
     * @type {number}
     * @memberof Detailedworkstats
     */
    'year'?: number;
    /**
     * 
     * @type {string}
     * @memberof Detailedworkstats
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Detailedworkstats
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof Detailedworkstats
     */
    'end'?: string;
    /**
     * 
     * @type {number}
     * @memberof Detailedworkstats
     */
    'bookedHours'?: number;
    /**
     * 
     * @type {DetailedworkstatsOrder}
     * @memberof Detailedworkstats
     */
    'order'?: DetailedworkstatsOrder;
    /**
     * 
     * @type {DetailedworkstatsForm}
     * @memberof Detailedworkstats
     */
    'form'?: DetailedworkstatsForm;
}
/**
 * 
 * @export
 * @interface DetailedworkstatsForm
 */
export interface DetailedworkstatsForm {
    /**
     * 
     * @type {string}
     * @memberof DetailedworkstatsForm
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedworkstatsForm
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DetailedworkstatsOrder
 */
export interface DetailedworkstatsOrder {
    /**
     * 
     * @type {number}
     * @memberof DetailedworkstatsOrder
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailedworkstatsOrder
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedworkstatsOrder
     */
    'reference'?: string;
}
/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface ExpeditionRecord
 */
export interface ExpeditionRecord {
    /**
     * 
     * @type {string}
     * @memberof ExpeditionRecord
     */
    'method': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionRecord
     */
    'deliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionRecord
     */
    'extTMSStatusURL'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionRecord
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof ExpeditionRecord
     */
    'customerId': number;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionRecord
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionRecord
     */
    'reference'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionRecord
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionRecord
     */
    'handledBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionRecord
     */
    'progress'?: string;
}
/**
 * 
 * @export
 * @interface ForceCreateSalesOrder200Response
 */
export interface ForceCreateSalesOrder200Response {
    /**
     * 
     * @type {string}
     * @memberof ForceCreateSalesOrder200Response
     */
    'status'?: string;
    /**
     * 
     * @type {any}
     * @memberof ForceCreateSalesOrder200Response
     */
    'data'?: any;
}
/**
 * 
 * @export
 * @interface FullTimeLog
 */
export interface FullTimeLog {
    /**
     * 
     * @type {number}
     * @memberof FullTimeLog
     */
    'totalTime'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FullTimeLog
     */
    'active'?: boolean;
    /**
     * 
     * @type {Array<FullTimeLogItem>}
     * @memberof FullTimeLog
     */
    'worklog'?: Array<FullTimeLogItem>;
}
/**
 * 
 * @export
 * @interface FullTimeLogItem
 */
export interface FullTimeLogItem {
    /**
     * 
     * @type {string}
     * @memberof FullTimeLogItem
     */
    'started'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullTimeLogItem
     */
    'stopped'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullTimeLogItem
     */
    'by'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FullTimeLogItem
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FullTimeLogItem
     */
    'formId'?: string;
}
/**
 * 
 * @export
 * @interface GetDelayStatistics200ResponseInner
 */
export interface GetDelayStatistics200ResponseInner {
    /**
     * 
     * @type {number}
     * @memberof GetDelayStatistics200ResponseInner
     */
    'orderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetDelayStatistics200ResponseInner
     */
    'worked'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetDelayStatistics200ResponseInner
     */
    'remaining'?: number;
}
/**
 * 
 * @export
 * @interface GetDeliveryById200Response
 */
export interface GetDeliveryById200Response {
    /**
     * 
     * @type {OrderTransport}
     * @memberof GetDeliveryById200Response
     */
    'transport'?: OrderTransport;
    /**
     * 
     * @type {CalendarEvent}
     * @memberof GetDeliveryById200Response
     */
    'event'?: CalendarEvent | null;
    /**
     * 
     * @type {Array<GetDeliveryById200ResponseCalendarIdsInner>}
     * @memberof GetDeliveryById200Response
     */
    'calendars'?: Array<GetDeliveryById200ResponseCalendarIdsInner>;
}
/**
 * 
 * @export
 * @interface GetDeliveryById200ResponseCalendarIdsInner
 */
export interface GetDeliveryById200ResponseCalendarIdsInner {
    /**
     * 
     * @type {string}
     * @memberof GetDeliveryById200ResponseCalendarIdsInner
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeliveryById200ResponseCalendarIdsInner
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface Healthy
 */
export interface Healthy {
    /**
     * 
     * @type {string}
     * @memberof Healthy
     */
    'service'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Healthy
     */
    'isAlive'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof Healthy
     */
    'cacheStats'?: object;
    /**
     * 
     * @type {object}
     * @memberof Healthy
     */
    'connectivity'?: object;
}
/**
 * 
 * @export
 * @interface OrderActionLog
 */
export interface OrderActionLog {
    /**
     * 
     * @type {number}
     * @memberof OrderActionLog
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderActionLog
     */
    'orderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderActionLog
     */
    'actionType'?: string;
    /**
     * 
     * @type {object}
     * @memberof OrderActionLog
     */
    'actionData'?: object;
    /**
     * 
     * @type {string}
     * @memberof OrderActionLog
     */
    'createdAt'?: string;
}
/**
 * 
 * @export
 * @interface OrderCopyRequestBody
 */
export interface OrderCopyRequestBody {
    /**
     * 
     * @type {number}
     * @memberof OrderCopyRequestBody
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderCopyRequestBody
     */
    'targetCustomerId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof OrderCopyRequestBody
     */
    'parts'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof OrderCopyRequestBody
     */
    'newTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderCopyRequestBody
     */
    'newReference'?: string;
}
/**
 * 
 * @export
 * @interface OrderDetails
 */
export interface OrderDetails {
    /**
     * 
     * @type {Array<ChangeLogItem>}
     * @memberof OrderDetails
     */
    'changelog'?: Array<ChangeLogItem>;
    /**
     * 
     * @type {OrderSummary}
     * @memberof OrderDetails
     */
    'summary'?: OrderSummary;
    /**
     * 
     * @type {object}
     * @memberof OrderDetails
     */
    'details'?: any;
    /**
     * 
     * @type {object}
     * @memberof OrderDetails
     */
    'formSpecification'?: any;
    /**
     * 
     * @type {object}
     * @memberof OrderDetails
     */
    'productSpecification'?: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof OrderDetails
     */
    'orderSpecification'?: Array<any>;
}
/**
 * 
 * @export
 * @interface OrderProgressSummary
 */
export interface OrderProgressSummary {
    /**
     * 
     * @type {string}
     * @memberof OrderProgressSummary
     */
    'formId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderProgressSummary
     */
    'formName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderProgressSummary
     */
    'approvalState'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderProgressSummary
     */
    'progressState'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderProgressSummary
     */
    'progressChangedBy'?: string;
}
/**
 * 
 * @export
 * @interface OrderStats
 */
export interface OrderStats {
    /**
     * 
     * @type {OrderStatsOpen}
     * @memberof OrderStats
     */
    'open'?: OrderStatsOpen;
    /**
     * 
     * @type {number}
     * @memberof OrderStats
     */
    'done'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderStats
     */
    'onhold'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderStats
     */
    'pendingpayment'?: number;
    /**
     * 
     * @type {OrderStatsAssigned}
     * @memberof OrderStats
     */
    'assigned'?: OrderStatsAssigned;
}
/**
 * 
 * @export
 * @interface OrderStatsAssigned
 */
export interface OrderStatsAssigned {
    /**
     * 
     * @type {number}
     * @memberof OrderStatsAssigned
     */
    'open'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderStatsAssigned
     */
    'other'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderStatsAssigned
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface OrderStatsOpen
 */
export interface OrderStatsOpen {
    /**
     * 
     * @type {number}
     * @memberof OrderStatsOpen
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderStatsOpen
     */
    'planned'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderStatsOpen
     */
    'nearDeadline'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderStatsOpen
     */
    'pastDeadline'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderStatsOpen
     */
    'aftercare'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderStatsOpen
     */
    'delivery'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderStatsOpen
     */
    'readyForTransport'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderStatsOpen
     */
    'totalWorktimeEstimation'?: number;
}
/**
 * 
 * @export
 * @interface OrderSummary
 */
export interface OrderSummary {
    /**
     * 
     * @type {number}
     * @memberof OrderSummary
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderSummary
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof OrderSummary
     */
    'state': OrderSummaryStateEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderSummary
     */
    'deadline'?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderSummary
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderSummary
     */
    'dateCreated'?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderSummary
     */
    'dateLastModified'?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderSummary
     */
    'assignedTo'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderSummary
     */
    'workedOnBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderSummary
     */
    'customerId'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderSummary
     */
    'orderTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderSummary
     */
    'paid'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderSummary
     */
    'reference'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderSummary
     */
    'hourlyRate'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderSummary
     */
    'extSalesOrderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderSummary
     */
    'extDocumentId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderSummary
     */
    'invoiced'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof OrderSummary
     */
    'invoiceNumber'?: number | null;
    /**
     * 
     * @type {Date|null}
     * @memberof OrderSummary
     */
    'reviewRequestSendDate'?: Date | null;
    /**
     * 
     * @type {Array<OrderProgressSummary>}
     * @memberof OrderSummary
     */
    'progressData'?: Array<OrderProgressSummary>;
    /**
     * 
     * @type {boolean}
     * @memberof OrderSummary
     */
    'isPlannedForDelivery'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderSummary
     */
    'hasBomItems'?: boolean;
}

export const OrderSummaryStateEnum = {
    Aftercare: 'aftercare',
    NearDeadline: 'near-deadline',
    PastDeadline: 'past-deadline',
    Planned: 'planned',
    Done: 'done',
    Cancelled: 'cancelled',
    PendingPayment: 'pending-payment',
    OnHold: 'on-hold',
    ReadyForTransport: 'ready-for-transport'
} as const;

export type OrderSummaryStateEnum = typeof OrderSummaryStateEnum[keyof typeof OrderSummaryStateEnum];

/**
 * 
 * @export
 * @interface OrderTransport
 */
export interface OrderTransport {
    /**
     * 
     * @type {number}
     * @memberof OrderTransport
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderTransport
     */
    'orderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTransport
     */
    'internalComment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderTransport
     */
    'by'?: OrderTransportByEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderTransport
     */
    'byAddition'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderTransport
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTransport
     */
    'sameAddress'?: OrderTransportSameAddressEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderTransport
     */
    'postalcode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderTransport
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderTransport
     */
    'numberSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderTransport
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderTransport
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderTransport
     */
    'country'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderTransport
     */
    'lat'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderTransport
     */
    'lon'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderTransport
     */
    'deliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderTransport
     */
    'extEventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTransport
     */
    'deliveryWindowPreference'?: string;
}

export const OrderTransportByEnum = {
    Mail: 'mail',
    External: 'external',
    Internal: 'internal',
    PickUp: 'pickUp',
    Other: 'other'
} as const;

export type OrderTransportByEnum = typeof OrderTransportByEnum[keyof typeof OrderTransportByEnum];
export const OrderTransportSameAddressEnum = {
    Yes: 'yes',
    No: 'no',
    NotApplicable: 'notApplicable'
} as const;

export type OrderTransportSameAddressEnum = typeof OrderTransportSameAddressEnum[keyof typeof OrderTransportSameAddressEnum];

/**
 * 
 * @export
 * @interface Orderpropagationstatistic
 */
export interface Orderpropagationstatistic {
    /**
     * 
     * @type {number}
     * @memberof Orderpropagationstatistic
     */
    'y'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Orderpropagationstatistic
     */
    'm'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Orderpropagationstatistic
     */
    'd'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Orderpropagationstatistic
     */
    'state'?: string;
    /**
     * 
     * @type {number}
     * @memberof Orderpropagationstatistic
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface OrdersInProgressRecord
 */
export interface OrdersInProgressRecord {
    /**
     * 
     * @type {number}
     * @memberof OrdersInProgressRecord
     */
    'orderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrdersInProgressRecord
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrdersInProgressRecord
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrdersInProgressRecord
     */
    'sectionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrdersInProgressRecord
     */
    'name'?: string;
    /**
     * unix timestamp
     * @type {number}
     * @memberof OrdersInProgressRecord
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersInProgressRecord
     */
    'customerId'?: number;
}
/**
 * 
 * @export
 * @interface OrdersWorkedOnFromRecord
 */
export interface OrdersWorkedOnFromRecord {
    /**
     * 
     * @type {number}
     * @memberof OrdersWorkedOnFromRecord
     */
    'orderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrdersWorkedOnFromRecord
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrdersWorkedOnFromRecord
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrdersWorkedOnFromRecord
     */
    'sectionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrdersWorkedOnFromRecord
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof OrdersWorkedOnFromRecord
     */
    'customerId'?: number;
    /**
     * unix timestamp when started
     * @type {number}
     * @memberof OrdersWorkedOnFromRecord
     */
    'start'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrdersWorkedOnFromRecord
     */
    'activeNow'?: boolean;
    /**
     * seconds spent in this session
     * @type {number}
     * @memberof OrdersWorkedOnFromRecord
     */
    'timeSpentNow'?: number;
    /**
     * seconds spent in total
     * @type {number}
     * @memberof OrdersWorkedOnFromRecord
     */
    'timeSpentTotal'?: number;
}
/**
 * planned work for a given week
 * @export
 * @interface Planningstats
 */
export interface Planningstats {
    /**
     * 
     * @type {number}
     * @memberof Planningstats
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof Planningstats
     */
    'week': number;
    /**
     * 
     * @type {number}
     * @memberof Planningstats
     */
    'availableHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof Planningstats
     */
    'alreadyBookedHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof Planningstats
     */
    'estimatedWorkHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof Planningstats
     */
    'remainingAvailableHours'?: number;
}
/**
 * 
 * @export
 * @interface ProposalAnswerBody
 */
export interface ProposalAnswerBody {
    /**
     * 
     * @type {number}
     * @memberof ProposalAnswerBody
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProposalAnswerBody
     */
    'proposalAccepted': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProposalAnswerBody
     */
    'proposalResponseTimeFrame'?: string;
}
/**
 * 
 * @export
 * @interface RemoveUserFromOrdersRequest
 */
export interface RemoveUserFromOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof RemoveUserFromOrdersRequest
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface SalesMarginRecord
 */
export interface SalesMarginRecord {
    /**
     * 
     * @type {number}
     * @memberof SalesMarginRecord
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof SalesMarginRecord
     */
    'sequence': number;
    /**
     * 
     * @type {string}
     * @memberof SalesMarginRecord
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof SalesMarginRecord
     */
    'reference': string;
    /**
     * 
     * @type {number}
     * @memberof SalesMarginRecord
     */
    'customerId': number;
    /**
     * 
     * @type {string}
     * @memberof SalesMarginRecord
     */
    'customerName': string;
    /**
     * 
     * @type {number}
     * @memberof SalesMarginRecord
     */
    'estimatedWorktime': number;
    /**
     * 
     * @type {number}
     * @memberof SalesMarginRecord
     */
    'estimatedWorkCost': number;
    /**
     * 
     * @type {number}
     * @memberof SalesMarginRecord
     */
    'currentWorktime': number;
    /**
     * 
     * @type {number}
     * @memberof SalesMarginRecord
     */
    'hourlyRate': number;
    /**
     * 
     * @type {number}
     * @memberof SalesMarginRecord
     */
    'estimatedMaterialCost': number;
    /**
     * 
     * @type {number}
     * @memberof SalesMarginRecord
     */
    'orderSalePrice': number;
    /**
     * 
     * @type {string}
     * @memberof SalesMarginRecord
     */
    'firstStarted': string;
    /**
     * 
     * @type {string}
     * @memberof SalesMarginRecord
     */
    'extSalesOrderLineId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SalesMarginRecord
     */
    'processed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SalesMarginRecord
     */
    'correctionWorktime'?: number;
    /**
     * 
     * @type {string}
     * @memberof SalesMarginRecord
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof SalesMarginRecord
     */
    'effectiveWorkCost'?: number;
}
/**
 * 
 * @export
 * @interface ScheduleItem
 */
export interface ScheduleItem {
    /**
     * 
     * @type {number}
     * @memberof ScheduleItem
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleItem
     */
    'parentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleItem
     */
    'dependsOn'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduleItem
     */
    'itemState'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleItem
     */
    'itemType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleItem
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleItem
     */
    'dateEnd': string;
    /**
     * 
     * @type {number}
     * @memberof ScheduleItem
     */
    'totalWorkHours': number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleItem
     */
    'workedHours': number;
}
/**
 * 
 * @export
 * @interface SetSalesMarginLogRequest
 */
export interface SetSalesMarginLogRequest {
    /**
     * 
     * @type {string}
     * @memberof SetSalesMarginLogRequest
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof SetSalesMarginLogRequest
     */
    'correctionWorktime'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SetSalesMarginLogRequest
     */
    'processed'?: boolean;
}
/**
 * 
 * @export
 * @interface SetWorkPlanningHoursBody
 */
export interface SetWorkPlanningHoursBody {
    /**
     * 
     * @type {number}
     * @memberof SetWorkPlanningHoursBody
     */
    'hours': number;
}
/**
 * 
 * @export
 * @interface TimeLogItem
 */
export interface TimeLogItem {
    /**
     * 
     * @type {string}
     * @memberof TimeLogItem
     */
    'lastStarted': string;
    /**
     * 
     * @type {string}
     * @memberof TimeLogItem
     */
    'lastStopped'?: string;
    /**
     * 
     * @type {number}
     * @memberof TimeLogItem
     */
    'totalTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof TimeLogItem
     */
    'by': string;
    /**
     * 
     * @type {boolean}
     * @memberof TimeLogItem
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface UpdateOrder200Response
 */
export interface UpdateOrder200Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrder200Response
     */
    'extDocumentId'?: string;
}
/**
 * 
 * @export
 * @interface WorkStatusLogItem
 */
export interface WorkStatusLogItem {
    /**
     * 
     * @type {number}
     * @memberof WorkStatusLogItem
     */
    'time': number;
    /**
     * 
     * @type {string}
     * @memberof WorkStatusLogItem
     */
    'by': string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkStatusLogItem
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface Workloadprognosis
 */
export interface Workloadprognosis {
    /**
     * 
     * @type {string}
     * @memberof Workloadprognosis
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof Workloadprognosis
     */
    'hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof Workloadprognosis
     */
    'surplus'?: number;
}
/**
 * work stats
 * @export
 * @interface Workstats
 */
export interface Workstats {
    /**
     * 
     * @type {string}
     * @memberof Workstats
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Workstats
     */
    'logcategory'?: string;
    /**
     * 
     * @type {number}
     * @memberof Workstats
     */
    'week': number;
    /**
     * 
     * @type {number}
     * @memberof Workstats
     */
    'day': number;
    /**
     * 
     * @type {number}
     * @memberof Workstats
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof Workstats
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof Workstats
     */
    'totaltime': number;
}

/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add delivery proposal(s)
         * @param {AddDeliveryProposalBody} [addDeliveryProposalBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProposals: async (addDeliveryProposalBody?: AddDeliveryProposalBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/delivery/proposals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDeliveryProposalBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Apply BOM templates to order
         * @summary Apply BOM Templates
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyBomTemplate: async (id: number, requestBody?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applyBomTemplate', 'id', id)
            const localVarPath = `/orders/order/{id}/applybomtemplates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * customer approval for a specified order / formId
         * @param {string} id 
         * @param {string} formId 
         * @param {Approval} [approval] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveOrderPart: async (id: string, formId: string, approval?: Approval, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveOrderPart', 'id', id)
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('approveOrderPart', 'formId', formId)
            const localVarPath = `/orders/order/{id}/approve/{formId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approval, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AssignTo} [assignTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignUserToOrder: async (id: string, assignTo?: AssignTo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignUserToOrder', 'id', id)
            const localVarPath = `/orders/order/{id}/assignTo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignTo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Copies a source order to a new order for a different customer
         * @summary Create a (partial) copy of an order for a (different) customer
         * @param {OrderCopyRequestBody} [orderCopyRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyOrder: async (orderCopyRequestBody?: OrderCopyRequestBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/order/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderCopyRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateInvoiceBody} [createInvoiceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice: async (createInvoiceBody?: CreateInvoiceBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvoiceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new order on the system
         * @summary Create new order
         * @param {OrderDetails} [orderDetails] Full description of the order to create (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder: async (orderDetails?: OrderDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete delivery event
         * @param {string} transportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDelivery: async (transportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transportId' is not null or undefined
            assertParamExists('deleteDelivery', 'transportId', transportId)
            const localVarPath = `/orders/transport/delivery/{transportId}`
                .replace(`{${"transportId"}}`, encodeURIComponent(String(transportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete delivery proposal(s)
         * @param {AddDeliveryProposalBody} [addDeliveryProposalBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProposals: async (addDeliveryProposalBody?: AddDeliveryProposalBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/delivery/proposals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDeliveryProposalBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ends a ready for transport order (i.e. The order has been delivered / picked-up)
         * @summary Finish up order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishOrder: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('finishOrder', 'id', id)
            const localVarPath = `/orders/order/{id}/finish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary force create a sales order in the external system for an order
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceCreateSalesOrder: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forceCreateSalesOrder', 'id', id)
            const localVarPath = `/orders/administration/forceCreateSalesOrder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get delivery event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceSync: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/administration/forceSync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch order propagation statistics
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveOrderPropagationStatistics: async (periodStart?: string, periodEnd?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/stats/order-propagation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (periodStart !== undefined) {
                localVarQueryParameter['periodStart'] = (periodStart as any instanceof Date) ?
                    (periodStart as any).toISOString() :
                    periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['periodEnd'] = (periodEnd as any instanceof Date) ?
                    (periodEnd as any).toISOString() :
                    periodEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches realtime view of orders currently being worked on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrderWorkInProgress: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/in-process-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all time spent work on specified order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkOnOrder: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAllWorkOnOrder', 'id', id)
            const localVarPath = `/orders/order/{id}/fulltimestatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Available Calendars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendars: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/transport/calendars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * fetch delay statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDelayStatistics: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/stats/delays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get delivery event
         * @param {string} transportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryById: async (transportId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transportId' is not null or undefined
            assertParamExists('getDeliveryById', 'transportId', transportId)
            const localVarPath = `/orders/transport/delivery/{transportId}`
                .replace(`{${"transportId"}}`, encodeURIComponent(String(transportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get expedition data
         * @param {string} [dd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpeditionData: async (dd?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/expedition/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dd !== undefined) {
                localVarQueryParameter['dd'] = (dd as any instanceof Date) ?
                    (dd as any).toISOString() :
                    dd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves order details for the given order Id (within tenant context)
         * @summary Get order details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrderById', 'id', id)
            const localVarPath = `/orders/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch order schedule data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderScheduleData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * fetch worklog statistics for factory users in given year/week
         * @param {number} year 
         * @param {number} week 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderWorklogDetailedStatsForFactoryUsers: async (year: number, week: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getOrderWorklogDetailedStatsForFactoryUsers', 'year', year)
            // verify required parameter 'week' is not null or undefined
            assertParamExists('getOrderWorklogDetailedStatsForFactoryUsers', 'week', week)
            const localVarPath = `/orders/stats/worklog/{year}/{week}/detailed`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"week"}}`, encodeURIComponent(String(week)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * fetch worklog statistics for factory users in given year/week
         * @param {number} year 
         * @param {number} week 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderWorklogStatsForFactoryUsers: async (year: number, week: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getOrderWorklogStatsForFactoryUsers', 'year', year)
            // verify required parameter 'week' is not null or undefined
            assertParamExists('getOrderWorklogStatsForFactoryUsers', 'week', week)
            const localVarPath = `/orders/stats/worklog/{year}/{week}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"week"}}`, encodeURIComponent(String(week)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a list of orders from the system\\n\\nReturns only the orders that are available for the logged-in tenant
         * @summary Fetch order list
         * @param {string} [type] Which orders should be fetched (i.e. all includes done)
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders: async (type?: string, pageSize?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of orders for specified customer (in tenant context)
         * @summary Get list of customer orders
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersCustomerid: async (customerid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerid' is not null or undefined
            assertParamExists('getOrdersCustomerid', 'customerid', customerid)
            const localVarPath = `/orders/list/{customerid}`
                .replace(`{${"customerid"}}`, encodeURIComponent(String(customerid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of orders used by or assigned to active user (in tenant context)
         * @summary Get list of orders used by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersInUseByActiveUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/list/inuseorassigned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of orders used by or assigned to user (in tenant context)
         * @summary Get list of orders used by user
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersInUseByUser: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getOrdersInUseByUser', 'name', name)
            const localVarPath = `/orders/list/inuseorassigned/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch the total order stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersStats: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of orders for Trengo
         * @summary Get list of customer orders from trengo
         * @param {number} ticketId 
         * @param {string} [authToken] Trengo auth token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersTicketId: async (ticketId: number, authToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getOrdersTicketId', 'ticketId', ticketId)
            const localVarPath = `/orders/list/trengo/{ticketId}`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authToken !== undefined) {
                localVarQueryParameter['auth_token'] = authToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve proposal data using the provided hashToken
         * @summary Retrieve proposal data using the provided hashToken
         * @param {string} hashToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposalByHash: async (hashToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hashToken' is not null or undefined
            assertParamExists('getProposalByHash', 'hashToken', hashToken)
            const localVarPath = `/orders/delivery/proposal/{hashToken}`
                .replace(`{${"hashToken"}}`, encodeURIComponent(String(hashToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve (all) delivery proposals with their status
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposals: async (date?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/delivery/proposals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve proposal data using the provided payload hash
         * @summary Retrieve proposal data using the provided payload hash
         * @param {string} hashToken 
         * @param {string} xTenantID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposalsForPayload: async (hashToken: string, xTenantID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hashToken' is not null or undefined
            assertParamExists('getProposalsForPayload', 'hashToken', hashToken)
            // verify required parameter 'xTenantID' is not null or undefined
            assertParamExists('getProposalsForPayload', 'xTenantID', xTenantID)
            const localVarPath = `/orders/delivery/proposal/byPayload/{hashToken}`
                .replace(`{${"hashToken"}}`, encodeURIComponent(String(hashToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (xTenantID != null) {
                localVarHeaderParameter['X-Tenant-ID'] = String(xTenantID);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * fetch margin statistics
         * @param {string} [orderId] sales margin for specific order
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesMarginStatistics: async (orderId?: string, from?: string, until?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/stats/salesmargins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substring(0,10) :
                    from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString().substring(0,10) :
                    until;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all transport items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransport: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/transport/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns time spent work on specified order by authenticated user
         * @param {string} id 
         * @param {string} [formId] get timestatus of a form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkOnOrder: async (id: string, formId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWorkOnOrder', 'id', id)
            const localVarPath = `/orders/order/{id}/timestatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (formId !== undefined) {
                localVarQueryParameter['formId'] = formId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * fetch planning statistics in given year
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkPlanningStats: async (year: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getWorkPlanningStats', 'year', year)
            const localVarPath = `/orders/stats/plannedwork/{year}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all time spent work on specified order/form aggregated by users
         * @param {string} id 
         * @param {string} formId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkStatusOnOrderForm: async (id: string, formId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWorkStatusOnOrderForm', 'id', id)
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('getWorkStatusOnOrderForm', 'formId', formId)
            const localVarPath = `/orders/order/{id}/{formId}/workstatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches realtime view of orders worked on today
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkedOnToday: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/stats/worked-on-today`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch order workload prognosis statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkloadPrognosis: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/stats/order-workload-prognosis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify the hours worked on a specific item for a specified worker
         * @summary Modify worked hours
         * @param {Detailedworkstats} [detailedworkstats] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyWorkOnOrder: async (detailedworkstats?: Detailedworkstats, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/work`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(detailedworkstats, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get notification send stats
         * @param {string} [dd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersDeliveryProposalNotificationstatsGet: async (dd?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/delivery/proposal/notificationstats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dd !== undefined) {
                localVarQueryParameter['dd'] = (dd as any instanceof Date) ?
                    (dd as any).toISOString() :
                    dd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process proposal answer
         * @param {string} hashToken 
         * @param {ProposalAnswerBody} [proposalAnswerBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProposalAnswer: async (hashToken: string, proposalAnswerBody?: ProposalAnswerBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hashToken' is not null or undefined
            assertParamExists('processProposalAnswer', 'hashToken', hashToken)
            const localVarPath = `/orders/delivery/proposal/{hashToken}/answer`
                .replace(`{${"hashToken"}}`, encodeURIComponent(String(hashToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proposalAnswerBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process proposal answer
         * @param {string} hashToken 
         * @param {string} xTenantID 
         * @param {ProposalAnswerBody} [proposalAnswerBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProposalAnswerWithPayload: async (hashToken: string, xTenantID: string, proposalAnswerBody?: ProposalAnswerBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hashToken' is not null or undefined
            assertParamExists('processProposalAnswerWithPayload', 'hashToken', hashToken)
            // verify required parameter 'xTenantID' is not null or undefined
            assertParamExists('processProposalAnswerWithPayload', 'xTenantID', xTenantID)
            const localVarPath = `/orders/delivery/proposal/byPayload/{hashToken}/answer`
                .replace(`{${"hashToken"}}`, encodeURIComponent(String(hashToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (xTenantID != null) {
                localVarHeaderParameter['X-Tenant-ID'] = String(xTenantID);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proposalAnswerBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove assigned user from orders
         * @param {RemoveUserFromOrdersRequest} [removeUserFromOrdersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromOrders: async (removeUserFromOrdersRequest?: RemoveUserFromOrdersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/order/removeUserFrom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeUserFromOrdersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dd 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotificationForDeliveryDate: async (dd: string, orderId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dd' is not null or undefined
            assertParamExists('sendNotificationForDeliveryDate', 'dd', dd)
            const localVarPath = `/orders/transport/delivery/sendNotifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dd !== undefined) {
                localVarQueryParameter['dd'] = (dd as any instanceof Date) ?
                    (dd as any).toISOString() :
                    dd;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary salesMargin log
         * @param {number} orderId 
         * @param {number} sequence 
         * @param {SetSalesMarginLogRequest} [setSalesMarginLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSalesMarginLog: async (orderId: number, sequence: number, setSalesMarginLogRequest?: SetSalesMarginLogRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('setSalesMarginLog', 'orderId', orderId)
            // verify required parameter 'sequence' is not null or undefined
            assertParamExists('setSalesMarginLog', 'sequence', sequence)
            const localVarPath = `/orders/stats/salesmarginLog/{orderId}/{sequence}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"sequence"}}`, encodeURIComponent(String(sequence)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setSalesMarginLogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set available hours for a specified year/week
         * @param {number} year 
         * @param {number} week 
         * @param {SetWorkPlanningHoursBody} [setWorkPlanningHoursBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWorkPlanningAvailableHours: async (year: number, week: number, setWorkPlanningHoursBody?: SetWorkPlanningHoursBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('setWorkPlanningAvailableHours', 'year', year)
            // verify required parameter 'week' is not null or undefined
            assertParamExists('setWorkPlanningAvailableHours', 'week', week)
            const localVarPath = `/orders/planning/availableHours/{year}/{week}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"week"}}`, encodeURIComponent(String(week)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setWorkPlanningHoursBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logs work start time for active user on given order
         * @summary Pick up order
         * @param {string} id 
         * @param {string} [formId] logs work start time for a form related to given order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startWorkOnOrder: async (id: string, formId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('startWorkOnOrder', 'id', id)
            const localVarPath = `/orders/order/{id}/pickup`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (formId !== undefined) {
                localVarQueryParameter['formId'] = formId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logs end time of work on specified order
         * @summary Release order
         * @param {string} id 
         * @param {string} [formId] logs work stop time for a form related to given order
         * @param {boolean} [allUsers] Apply on all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopWorkOnOrder: async (id: string, formId?: string, allUsers?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stopWorkOnOrder', 'id', id)
            const localVarPath = `/orders/order/{id}/release`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (formId !== undefined) {
                localVarQueryParameter['formId'] = formId;
            }

            if (allUsers !== undefined) {
                localVarQueryParameter['allUsers'] = allUsers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or update a google calendar delivery event
         * @param {CalendarEvent | null} [calendarEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDelivery: async (calendarEvent?: CalendarEvent | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/transport/delivery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates given order with the new specification
         * @summary Update order specification
         * @param {string} id 
         * @param {string} formId 
         * @param {OrderDetails} [orderDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder: async (id: string, formId: string, orderDetails?: OrderDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrder', 'id', id)
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('updateOrder', 'formId', formId)
            const localVarPath = `/orders/order/{id}/{formId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update order product
         * @param {string} id 
         * @param {number} sequence 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderProduct: async (id: string, sequence: number, body?: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrderProduct', 'id', id)
            // verify required parameter 'sequence' is not null or undefined
            assertParamExists('updateOrderProduct', 'sequence', sequence)
            const localVarPath = `/orders/order/{id}/orderproduct/{sequence}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"sequence"}}`, encodeURIComponent(String(sequence)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * order progress update for a specified order / formId
         * @param {string} id 
         * @param {Array<OrderProgressSummary>} [orderProgressSummary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderProgress: async (id: string, orderProgressSummary?: Array<OrderProgressSummary>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrderProgress', 'id', id)
            const localVarPath = `/orders/order/{id}/progress`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderProgressSummary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Order Summary
         * @param {string} id 
         * @param {boolean} [cleanupStale] boolean used to force cleanup depending on operation and user role
         * @param {OrderSummary} [orderSummary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderSummary: async (id: string, cleanupStale?: boolean, orderSummary?: OrderSummary, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrderSummary', 'id', id)
            const localVarPath = `/orders/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cleanupStale !== undefined) {
                localVarQueryParameter['cleanupStale'] = cleanupStale;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderSummary, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add delivery proposal(s)
         * @param {AddDeliveryProposalBody} [addDeliveryProposalBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProposals(addDeliveryProposalBody?: AddDeliveryProposalBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryProposal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProposals(addDeliveryProposalBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.addProposals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Apply BOM templates to order
         * @summary Apply BOM Templates
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyBomTemplate(id: number, requestBody?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyBomTemplate(id, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.applyBomTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * customer approval for a specified order / formId
         * @param {string} id 
         * @param {string} formId 
         * @param {Approval} [approval] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveOrderPart(id: string, formId: string, approval?: Approval, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveOrderPart(id, formId, approval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.approveOrderPart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {AssignTo} [assignTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignUserToOrder(id: string, assignTo?: AssignTo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignUserToOrder(id, assignTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.assignUserToOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Copies a source order to a new order for a different customer
         * @summary Create a (partial) copy of an order for a (different) customer
         * @param {OrderCopyRequestBody} [orderCopyRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyOrder(orderCopyRequestBody?: OrderCopyRequestBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CopyOrder200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyOrder(orderCopyRequestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.copyOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary 
         * @param {CreateInvoiceBody} [createInvoiceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoice(createInvoiceBody?: CreateInvoiceBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvoice(createInvoiceBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.createInvoice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new order on the system
         * @summary Create new order
         * @param {OrderDetails} [orderDetails] Full description of the order to create (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrder(orderDetails?: OrderDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrder(orderDetails, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.createOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete delivery event
         * @param {string} transportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDelivery(transportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDelivery(transportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.deleteDelivery']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete delivery proposal(s)
         * @param {AddDeliveryProposalBody} [addDeliveryProposalBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProposals(addDeliveryProposalBody?: AddDeliveryProposalBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResultBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProposals(addDeliveryProposalBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.deleteProposals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Ends a ready for transport order (i.e. The order has been delivered / picked-up)
         * @summary Finish up order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishOrder(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResultBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishOrder(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.finishOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary force create a sales order in the external system for an order
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceCreateSalesOrder(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForceCreateSalesOrder200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceCreateSalesOrder(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.forceCreateSalesOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get delivery event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceSync(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForceCreateSalesOrder200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceSync(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.forceSync']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch order propagation statistics
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveOrderPropagationStatistics(periodStart?: string, periodEnd?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Orderpropagationstatistic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveOrderPropagationStatistics(periodStart, periodEnd, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getActiveOrderPropagationStatistics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches realtime view of orders currently being worked on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllOrderWorkInProgress(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrdersInProgressRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOrderWorkInProgress(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getAllOrderWorkInProgress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all time spent work on specified order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWorkOnOrder(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullTimeLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWorkOnOrder(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getAllWorkOnOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Available Calendars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendars(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarsInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendars(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getCalendars']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * fetch delay statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDelayStatistics(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetDelayStatistics200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDelayStatistics(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getDelayStatistics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get delivery event
         * @param {string} transportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryById(transportId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeliveryById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveryById(transportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getDeliveryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get expedition data
         * @param {string} [dd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExpeditionData(dd?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExpeditionRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExpeditionData(dd, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getExpeditionData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Healthy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getHealth']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves order details for the given order Id (within tenant context)
         * @summary Get order details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getOrderById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch order schedule data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderScheduleData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduleItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderScheduleData(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getOrderScheduleData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * fetch worklog statistics for factory users in given year/week
         * @param {number} year 
         * @param {number} week 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderWorklogDetailedStatsForFactoryUsers(year: number, week: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Detailedworkstats>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderWorklogDetailedStatsForFactoryUsers(year, week, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getOrderWorklogDetailedStatsForFactoryUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * fetch worklog statistics for factory users in given year/week
         * @param {number} year 
         * @param {number} week 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderWorklogStatsForFactoryUsers(year: number, week: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Workstats>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderWorklogStatsForFactoryUsers(year, week, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getOrderWorklogStatsForFactoryUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch a list of orders from the system\\n\\nReturns only the orders that are available for the logged-in tenant
         * @summary Fetch order list
         * @param {string} [type] Which orders should be fetched (i.e. all includes done)
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrders(type?: string, pageSize?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrders(type, pageSize, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getOrders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get list of orders for specified customer (in tenant context)
         * @summary Get list of customer orders
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersCustomerid(customerid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersCustomerid(customerid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getOrdersCustomerid']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get list of orders used by or assigned to active user (in tenant context)
         * @summary Get list of orders used by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersInUseByActiveUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersInUseByActiveUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getOrdersInUseByActiveUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get list of orders used by or assigned to user (in tenant context)
         * @summary Get list of orders used by user
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersInUseByUser(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersInUseByUser(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getOrdersInUseByUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch the total order stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersStats(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersStats(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getOrdersStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get list of orders for Trengo
         * @summary Get list of customer orders from trengo
         * @param {number} ticketId 
         * @param {string} [authToken] Trengo auth token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersTicketId(ticketId: number, authToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersTicketId(ticketId, authToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getOrdersTicketId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve proposal data using the provided hashToken
         * @summary Retrieve proposal data using the provided hashToken
         * @param {string} hashToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProposalByHash(hashToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryProposal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProposalByHash(hashToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getProposalByHash']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieve (all) delivery proposals with their status
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProposals(date?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryProposal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProposals(date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getProposals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve proposal data using the provided payload hash
         * @summary Retrieve proposal data using the provided payload hash
         * @param {string} hashToken 
         * @param {string} xTenantID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProposalsForPayload(hashToken: string, xTenantID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryProposal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProposalsForPayload(hashToken, xTenantID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getProposalsForPayload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * fetch margin statistics
         * @param {string} [orderId] sales margin for specific order
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSalesMarginStatistics(orderId?: string, from?: string, until?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SalesMarginRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSalesMarginStatistics(orderId, from, until, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getSalesMarginStatistics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List all transport items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransport(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderTransport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransport(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getTransport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns time spent work on specified order by authenticated user
         * @param {string} id 
         * @param {string} [formId] get timestatus of a form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkOnOrder(id: string, formId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeLogItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkOnOrder(id, formId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getWorkOnOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * fetch planning statistics in given year
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkPlanningStats(year: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Planningstats>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkPlanningStats(year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getWorkPlanningStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all time spent work on specified order/form aggregated by users
         * @param {string} id 
         * @param {string} formId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkStatusOnOrderForm(id: string, formId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkStatusLogItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkStatusOnOrderForm(id, formId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getWorkStatusOnOrderForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches realtime view of orders worked on today
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkedOnToday(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrdersWorkedOnFromRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkedOnToday(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getWorkedOnToday']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch order workload prognosis statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkloadPrognosis(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Workloadprognosis>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkloadPrognosis(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.getWorkloadPrognosis']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Modify the hours worked on a specific item for a specified worker
         * @summary Modify worked hours
         * @param {Detailedworkstats} [detailedworkstats] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyWorkOnOrder(detailedworkstats?: Detailedworkstats, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyWorkOnOrder(detailedworkstats, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.modifyWorkOnOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get notification send stats
         * @param {string} [dd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersDeliveryProposalNotificationstatsGet(dd?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderActionLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersDeliveryProposalNotificationstatsGet(dd, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.ordersDeliveryProposalNotificationstatsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Process proposal answer
         * @param {string} hashToken 
         * @param {ProposalAnswerBody} [proposalAnswerBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processProposalAnswer(hashToken: string, proposalAnswerBody?: ProposalAnswerBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResultBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processProposalAnswer(hashToken, proposalAnswerBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.processProposalAnswer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Process proposal answer
         * @param {string} hashToken 
         * @param {string} xTenantID 
         * @param {ProposalAnswerBody} [proposalAnswerBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processProposalAnswerWithPayload(hashToken: string, xTenantID: string, proposalAnswerBody?: ProposalAnswerBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionResultBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processProposalAnswerWithPayload(hashToken, xTenantID, proposalAnswerBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.processProposalAnswerWithPayload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove assigned user from orders
         * @param {RemoveUserFromOrdersRequest} [removeUserFromOrdersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromOrders(removeUserFromOrdersRequest?: RemoveUserFromOrdersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserFromOrders(removeUserFromOrdersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.removeUserFromOrders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} dd 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotificationForDeliveryDate(dd: string, orderId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotificationForDeliveryDate(dd, orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.sendNotificationForDeliveryDate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary salesMargin log
         * @param {number} orderId 
         * @param {number} sequence 
         * @param {SetSalesMarginLogRequest} [setSalesMarginLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setSalesMarginLog(orderId: number, sequence: number, setSalesMarginLogRequest?: SetSalesMarginLogRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setSalesMarginLog(orderId, sequence, setSalesMarginLogRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.setSalesMarginLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * set available hours for a specified year/week
         * @param {number} year 
         * @param {number} week 
         * @param {SetWorkPlanningHoursBody} [setWorkPlanningHoursBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setWorkPlanningAvailableHours(year: number, week: number, setWorkPlanningHoursBody?: SetWorkPlanningHoursBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setWorkPlanningAvailableHours(year, week, setWorkPlanningHoursBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.setWorkPlanningAvailableHours']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Logs work start time for active user on given order
         * @summary Pick up order
         * @param {string} id 
         * @param {string} [formId] logs work start time for a form related to given order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startWorkOnOrder(id: string, formId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startWorkOnOrder(id, formId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.startWorkOnOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Logs end time of work on specified order
         * @summary Release order
         * @param {string} id 
         * @param {string} [formId] logs work stop time for a form related to given order
         * @param {boolean} [allUsers] Apply on all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopWorkOnOrder(id: string, formId?: string, allUsers?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopWorkOnOrder(id, formId, allUsers, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.stopWorkOnOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create or update a google calendar delivery event
         * @param {CalendarEvent | null} [calendarEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDelivery(calendarEvent?: CalendarEvent | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDelivery(calendarEvent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.updateDelivery']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates given order with the new specification
         * @summary Update order specification
         * @param {string} id 
         * @param {string} formId 
         * @param {OrderDetails} [orderDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrder(id: string, formId: string, orderDetails?: OrderDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateOrder200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrder(id, formId, orderDetails, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.updateOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update order product
         * @param {string} id 
         * @param {number} sequence 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderProduct(id: string, sequence: number, body?: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateOrder200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderProduct(id, sequence, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.updateOrderProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * order progress update for a specified order / formId
         * @param {string} id 
         * @param {Array<OrderProgressSummary>} [orderProgressSummary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderProgress(id: string, orderProgressSummary?: Array<OrderProgressSummary>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderProgress(id, orderProgressSummary, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.updateOrderProgress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Order Summary
         * @param {string} id 
         * @param {boolean} [cleanupStale] boolean used to force cleanup depending on operation and user role
         * @param {OrderSummary} [orderSummary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderSummary(id: string, cleanupStale?: boolean, orderSummary?: OrderSummary, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderSummary(id, cleanupStale, orderSummary, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.updateOrderSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @summary Add delivery proposal(s)
         * @param {AddDeliveryProposalBody} [addDeliveryProposalBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProposals(addDeliveryProposalBody?: AddDeliveryProposalBody, options?: RawAxiosRequestConfig): AxiosPromise<Array<DeliveryProposal>> {
            return localVarFp.addProposals(addDeliveryProposalBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Apply BOM templates to order
         * @summary Apply BOM Templates
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyBomTemplate(id: number, requestBody?: Array<number>, options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.applyBomTemplate(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * customer approval for a specified order / formId
         * @param {string} id 
         * @param {string} formId 
         * @param {Approval} [approval] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveOrderPart(id: string, formId: string, approval?: Approval, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.approveOrderPart(id, formId, approval, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AssignTo} [assignTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignUserToOrder(id: string, assignTo?: AssignTo, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.assignUserToOrder(id, assignTo, options).then((request) => request(axios, basePath));
        },
        /**
         * Copies a source order to a new order for a different customer
         * @summary Create a (partial) copy of an order for a (different) customer
         * @param {OrderCopyRequestBody} [orderCopyRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyOrder(orderCopyRequestBody?: OrderCopyRequestBody, options?: RawAxiosRequestConfig): AxiosPromise<CopyOrder200Response> {
            return localVarFp.copyOrder(orderCopyRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateInvoiceBody} [createInvoiceBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoice(createInvoiceBody?: CreateInvoiceBody, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createInvoice(createInvoiceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new order on the system
         * @summary Create new order
         * @param {OrderDetails} [orderDetails] Full description of the order to create (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(orderDetails?: OrderDetails, options?: RawAxiosRequestConfig): AxiosPromise<CreatedResponse> {
            return localVarFp.createOrder(orderDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete delivery event
         * @param {string} transportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDelivery(transportId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteDelivery(transportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete delivery proposal(s)
         * @param {AddDeliveryProposalBody} [addDeliveryProposalBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProposals(addDeliveryProposalBody?: AddDeliveryProposalBody, options?: RawAxiosRequestConfig): AxiosPromise<ActionResultBody> {
            return localVarFp.deleteProposals(addDeliveryProposalBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Ends a ready for transport order (i.e. The order has been delivered / picked-up)
         * @summary Finish up order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishOrder(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ActionResultBody> {
            return localVarFp.finishOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary force create a sales order in the external system for an order
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceCreateSalesOrder(id: number, options?: RawAxiosRequestConfig): AxiosPromise<ForceCreateSalesOrder200Response> {
            return localVarFp.forceCreateSalesOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get delivery event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceSync(options?: RawAxiosRequestConfig): AxiosPromise<ForceCreateSalesOrder200Response> {
            return localVarFp.forceSync(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch order propagation statistics
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveOrderPropagationStatistics(periodStart?: string, periodEnd?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Orderpropagationstatistic>> {
            return localVarFp.getActiveOrderPropagationStatistics(periodStart, periodEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches realtime view of orders currently being worked on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrderWorkInProgress(options?: RawAxiosRequestConfig): AxiosPromise<Array<OrdersInProgressRecord>> {
            return localVarFp.getAllOrderWorkInProgress(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all time spent work on specified order
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkOnOrder(id: string, options?: RawAxiosRequestConfig): AxiosPromise<FullTimeLog> {
            return localVarFp.getAllWorkOnOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Available Calendars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendars(options?: RawAxiosRequestConfig): AxiosPromise<Array<CalendarsInner>> {
            return localVarFp.getCalendars(options).then((request) => request(axios, basePath));
        },
        /**
         * fetch delay statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDelayStatistics(options?: RawAxiosRequestConfig): AxiosPromise<Array<GetDelayStatistics200ResponseInner>> {
            return localVarFp.getDelayStatistics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get delivery event
         * @param {string} transportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryById(transportId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetDeliveryById200Response> {
            return localVarFp.getDeliveryById(transportId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get expedition data
         * @param {string} [dd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpeditionData(dd?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ExpeditionRecord>> {
            return localVarFp.getExpeditionData(dd, options).then((request) => request(axios, basePath));
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: RawAxiosRequestConfig): AxiosPromise<Healthy> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves order details for the given order Id (within tenant context)
         * @summary Get order details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<OrderDetails> {
            return localVarFp.getOrderById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch order schedule data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderScheduleData(options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduleItem>> {
            return localVarFp.getOrderScheduleData(options).then((request) => request(axios, basePath));
        },
        /**
         * fetch worklog statistics for factory users in given year/week
         * @param {number} year 
         * @param {number} week 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderWorklogDetailedStatsForFactoryUsers(year: number, week: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<Detailedworkstats>> {
            return localVarFp.getOrderWorklogDetailedStatsForFactoryUsers(year, week, options).then((request) => request(axios, basePath));
        },
        /**
         * fetch worklog statistics for factory users in given year/week
         * @param {number} year 
         * @param {number} week 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderWorklogStatsForFactoryUsers(year: number, week: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<Workstats>> {
            return localVarFp.getOrderWorklogStatsForFactoryUsers(year, week, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a list of orders from the system\\n\\nReturns only the orders that are available for the logged-in tenant
         * @summary Fetch order list
         * @param {string} [type] Which orders should be fetched (i.e. all includes done)
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders(type?: string, pageSize?: number, page?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<OrderSummary>> {
            return localVarFp.getOrders(type, pageSize, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of orders for specified customer (in tenant context)
         * @summary Get list of customer orders
         * @param {string} customerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersCustomerid(customerid: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<OrderSummary>> {
            return localVarFp.getOrdersCustomerid(customerid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of orders used by or assigned to active user (in tenant context)
         * @summary Get list of orders used by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersInUseByActiveUser(options?: RawAxiosRequestConfig): AxiosPromise<Array<OrderSummary>> {
            return localVarFp.getOrdersInUseByActiveUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of orders used by or assigned to user (in tenant context)
         * @summary Get list of orders used by user
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersInUseByUser(name: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<OrderSummary>> {
            return localVarFp.getOrdersInUseByUser(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch the total order stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersStats(options?: RawAxiosRequestConfig): AxiosPromise<OrderStats> {
            return localVarFp.getOrdersStats(options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of orders for Trengo
         * @summary Get list of customer orders from trengo
         * @param {number} ticketId 
         * @param {string} [authToken] Trengo auth token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersTicketId(ticketId: number, authToken?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<OrderSummary>> {
            return localVarFp.getOrdersTicketId(ticketId, authToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve proposal data using the provided hashToken
         * @summary Retrieve proposal data using the provided hashToken
         * @param {string} hashToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposalByHash(hashToken: string, options?: RawAxiosRequestConfig): AxiosPromise<DeliveryProposal> {
            return localVarFp.getProposalByHash(hashToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve (all) delivery proposals with their status
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposals(date?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DeliveryProposal>> {
            return localVarFp.getProposals(date, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve proposal data using the provided payload hash
         * @summary Retrieve proposal data using the provided payload hash
         * @param {string} hashToken 
         * @param {string} xTenantID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposalsForPayload(hashToken: string, xTenantID: string, options?: RawAxiosRequestConfig): AxiosPromise<DeliveryProposal> {
            return localVarFp.getProposalsForPayload(hashToken, xTenantID, options).then((request) => request(axios, basePath));
        },
        /**
         * fetch margin statistics
         * @param {string} [orderId] sales margin for specific order
         * @param {string} [from] 
         * @param {string} [until] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesMarginStatistics(orderId?: string, from?: string, until?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<SalesMarginRecord>> {
            return localVarFp.getSalesMarginStatistics(orderId, from, until, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all transport items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransport(options?: RawAxiosRequestConfig): AxiosPromise<Array<OrderTransport>> {
            return localVarFp.getTransport(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns time spent work on specified order by authenticated user
         * @param {string} id 
         * @param {string} [formId] get timestatus of a form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkOnOrder(id: string, formId?: string, options?: RawAxiosRequestConfig): AxiosPromise<TimeLogItem> {
            return localVarFp.getWorkOnOrder(id, formId, options).then((request) => request(axios, basePath));
        },
        /**
         * fetch planning statistics in given year
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkPlanningStats(year: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<Planningstats>> {
            return localVarFp.getWorkPlanningStats(year, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all time spent work on specified order/form aggregated by users
         * @param {string} id 
         * @param {string} formId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkStatusOnOrderForm(id: string, formId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<WorkStatusLogItem>> {
            return localVarFp.getWorkStatusOnOrderForm(id, formId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches realtime view of orders worked on today
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkedOnToday(options?: RawAxiosRequestConfig): AxiosPromise<Array<OrdersWorkedOnFromRecord>> {
            return localVarFp.getWorkedOnToday(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch order workload prognosis statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkloadPrognosis(options?: RawAxiosRequestConfig): AxiosPromise<Array<Workloadprognosis>> {
            return localVarFp.getWorkloadPrognosis(options).then((request) => request(axios, basePath));
        },
        /**
         * Modify the hours worked on a specific item for a specified worker
         * @summary Modify worked hours
         * @param {Detailedworkstats} [detailedworkstats] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyWorkOnOrder(detailedworkstats?: Detailedworkstats, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.modifyWorkOnOrder(detailedworkstats, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get notification send stats
         * @param {string} [dd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersDeliveryProposalNotificationstatsGet(dd?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<OrderActionLog>> {
            return localVarFp.ordersDeliveryProposalNotificationstatsGet(dd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process proposal answer
         * @param {string} hashToken 
         * @param {ProposalAnswerBody} [proposalAnswerBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProposalAnswer(hashToken: string, proposalAnswerBody?: ProposalAnswerBody, options?: RawAxiosRequestConfig): AxiosPromise<ActionResultBody> {
            return localVarFp.processProposalAnswer(hashToken, proposalAnswerBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process proposal answer
         * @param {string} hashToken 
         * @param {string} xTenantID 
         * @param {ProposalAnswerBody} [proposalAnswerBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProposalAnswerWithPayload(hashToken: string, xTenantID: string, proposalAnswerBody?: ProposalAnswerBody, options?: RawAxiosRequestConfig): AxiosPromise<ActionResultBody> {
            return localVarFp.processProposalAnswerWithPayload(hashToken, xTenantID, proposalAnswerBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove assigned user from orders
         * @param {RemoveUserFromOrdersRequest} [removeUserFromOrdersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromOrders(removeUserFromOrdersRequest?: RemoveUserFromOrdersRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeUserFromOrders(removeUserFromOrdersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dd 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotificationForDeliveryDate(dd: string, orderId?: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sendNotificationForDeliveryDate(dd, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary salesMargin log
         * @param {number} orderId 
         * @param {number} sequence 
         * @param {SetSalesMarginLogRequest} [setSalesMarginLogRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSalesMarginLog(orderId: number, sequence: number, setSalesMarginLogRequest?: SetSalesMarginLogRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.setSalesMarginLog(orderId, sequence, setSalesMarginLogRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * set available hours for a specified year/week
         * @param {number} year 
         * @param {number} week 
         * @param {SetWorkPlanningHoursBody} [setWorkPlanningHoursBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWorkPlanningAvailableHours(year: number, week: number, setWorkPlanningHoursBody?: SetWorkPlanningHoursBody, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.setWorkPlanningAvailableHours(year, week, setWorkPlanningHoursBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Logs work start time for active user on given order
         * @summary Pick up order
         * @param {string} id 
         * @param {string} [formId] logs work start time for a form related to given order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startWorkOnOrder(id: string, formId?: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.startWorkOnOrder(id, formId, options).then((request) => request(axios, basePath));
        },
        /**
         * Logs end time of work on specified order
         * @summary Release order
         * @param {string} id 
         * @param {string} [formId] logs work stop time for a form related to given order
         * @param {boolean} [allUsers] Apply on all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopWorkOnOrder(id: string, formId?: string, allUsers?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stopWorkOnOrder(id, formId, allUsers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or update a google calendar delivery event
         * @param {CalendarEvent | null} [calendarEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDelivery(calendarEvent?: CalendarEvent | null, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateDelivery(calendarEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates given order with the new specification
         * @summary Update order specification
         * @param {string} id 
         * @param {string} formId 
         * @param {OrderDetails} [orderDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder(id: string, formId: string, orderDetails?: OrderDetails, options?: RawAxiosRequestConfig): AxiosPromise<UpdateOrder200Response> {
            return localVarFp.updateOrder(id, formId, orderDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update order product
         * @param {string} id 
         * @param {number} sequence 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderProduct(id: string, sequence: number, body?: object, options?: RawAxiosRequestConfig): AxiosPromise<UpdateOrder200Response> {
            return localVarFp.updateOrderProduct(id, sequence, body, options).then((request) => request(axios, basePath));
        },
        /**
         * order progress update for a specified order / formId
         * @param {string} id 
         * @param {Array<OrderProgressSummary>} [orderProgressSummary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderProgress(id: string, orderProgressSummary?: Array<OrderProgressSummary>, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateOrderProgress(id, orderProgressSummary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Order Summary
         * @param {string} id 
         * @param {boolean} [cleanupStale] boolean used to force cleanup depending on operation and user role
         * @param {OrderSummary} [orderSummary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderSummary(id: string, cleanupStale?: boolean, orderSummary?: OrderSummary, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateOrderSummary(id, cleanupStale, orderSummary, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @summary Add delivery proposal(s)
     * @param {AddDeliveryProposalBody} [addDeliveryProposalBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public addProposals(addDeliveryProposalBody?: AddDeliveryProposalBody, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).addProposals(addDeliveryProposalBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Apply BOM templates to order
     * @summary Apply BOM Templates
     * @param {number} id 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public applyBomTemplate(id: number, requestBody?: Array<number>, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).applyBomTemplate(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * customer approval for a specified order / formId
     * @param {string} id 
     * @param {string} formId 
     * @param {Approval} [approval] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public approveOrderPart(id: string, formId: string, approval?: Approval, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).approveOrderPart(id, formId, approval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {AssignTo} [assignTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public assignUserToOrder(id: string, assignTo?: AssignTo, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).assignUserToOrder(id, assignTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Copies a source order to a new order for a different customer
     * @summary Create a (partial) copy of an order for a (different) customer
     * @param {OrderCopyRequestBody} [orderCopyRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public copyOrder(orderCopyRequestBody?: OrderCopyRequestBody, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).copyOrder(orderCopyRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateInvoiceBody} [createInvoiceBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public createInvoice(createInvoiceBody?: CreateInvoiceBody, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).createInvoice(createInvoiceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new order on the system
     * @summary Create new order
     * @param {OrderDetails} [orderDetails] Full description of the order to create (including meta data)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public createOrder(orderDetails?: OrderDetails, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).createOrder(orderDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete delivery event
     * @param {string} transportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public deleteDelivery(transportId: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).deleteDelivery(transportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete delivery proposal(s)
     * @param {AddDeliveryProposalBody} [addDeliveryProposalBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public deleteProposals(addDeliveryProposalBody?: AddDeliveryProposalBody, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).deleteProposals(addDeliveryProposalBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ends a ready for transport order (i.e. The order has been delivered / picked-up)
     * @summary Finish up order
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public finishOrder(id: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).finishOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary force create a sales order in the external system for an order
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public forceCreateSalesOrder(id: number, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).forceCreateSalesOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get delivery event
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public forceSync(options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).forceSync(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch order propagation statistics
     * @param {string} [periodStart] 
     * @param {string} [periodEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getActiveOrderPropagationStatistics(periodStart?: string, periodEnd?: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getActiveOrderPropagationStatistics(periodStart, periodEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches realtime view of orders currently being worked on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getAllOrderWorkInProgress(options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getAllOrderWorkInProgress(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all time spent work on specified order
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getAllWorkOnOrder(id: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getAllWorkOnOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Available Calendars
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getCalendars(options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getCalendars(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * fetch delay statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getDelayStatistics(options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getDelayStatistics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get delivery event
     * @param {string} transportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getDeliveryById(transportId: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getDeliveryById(transportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get expedition data
     * @param {string} [dd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getExpeditionData(dd?: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getExpeditionData(dd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Determine service health
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getHealth(options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves order details for the given order Id (within tenant context)
     * @summary Get order details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrderById(id: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrderById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch order schedule data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrderScheduleData(options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrderScheduleData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * fetch worklog statistics for factory users in given year/week
     * @param {number} year 
     * @param {number} week 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrderWorklogDetailedStatsForFactoryUsers(year: number, week: number, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrderWorklogDetailedStatsForFactoryUsers(year, week, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * fetch worklog statistics for factory users in given year/week
     * @param {number} year 
     * @param {number} week 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrderWorklogStatsForFactoryUsers(year: number, week: number, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrderWorklogStatsForFactoryUsers(year, week, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a list of orders from the system\\n\\nReturns only the orders that are available for the logged-in tenant
     * @summary Fetch order list
     * @param {string} [type] Which orders should be fetched (i.e. all includes done)
     * @param {number} [pageSize] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrders(type?: string, pageSize?: number, page?: number, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrders(type, pageSize, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of orders for specified customer (in tenant context)
     * @summary Get list of customer orders
     * @param {string} customerid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrdersCustomerid(customerid: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrdersCustomerid(customerid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of orders used by or assigned to active user (in tenant context)
     * @summary Get list of orders used by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrdersInUseByActiveUser(options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrdersInUseByActiveUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of orders used by or assigned to user (in tenant context)
     * @summary Get list of orders used by user
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrdersInUseByUser(name: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrdersInUseByUser(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch the total order stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrdersStats(options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrdersStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of orders for Trengo
     * @summary Get list of customer orders from trengo
     * @param {number} ticketId 
     * @param {string} [authToken] Trengo auth token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrdersTicketId(ticketId: number, authToken?: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getOrdersTicketId(ticketId, authToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve proposal data using the provided hashToken
     * @summary Retrieve proposal data using the provided hashToken
     * @param {string} hashToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getProposalByHash(hashToken: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getProposalByHash(hashToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve (all) delivery proposals with their status
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getProposals(date?: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getProposals(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve proposal data using the provided payload hash
     * @summary Retrieve proposal data using the provided payload hash
     * @param {string} hashToken 
     * @param {string} xTenantID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getProposalsForPayload(hashToken: string, xTenantID: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getProposalsForPayload(hashToken, xTenantID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * fetch margin statistics
     * @param {string} [orderId] sales margin for specific order
     * @param {string} [from] 
     * @param {string} [until] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getSalesMarginStatistics(orderId?: string, from?: string, until?: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getSalesMarginStatistics(orderId, from, until, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all transport items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getTransport(options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getTransport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns time spent work on specified order by authenticated user
     * @param {string} id 
     * @param {string} [formId] get timestatus of a form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getWorkOnOrder(id: string, formId?: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getWorkOnOrder(id, formId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * fetch planning statistics in given year
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getWorkPlanningStats(year: number, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getWorkPlanningStats(year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all time spent work on specified order/form aggregated by users
     * @param {string} id 
     * @param {string} formId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getWorkStatusOnOrderForm(id: string, formId: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getWorkStatusOnOrderForm(id, formId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches realtime view of orders worked on today
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getWorkedOnToday(options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getWorkedOnToday(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch order workload prognosis statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getWorkloadPrognosis(options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).getWorkloadPrognosis(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify the hours worked on a specific item for a specified worker
     * @summary Modify worked hours
     * @param {Detailedworkstats} [detailedworkstats] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public modifyWorkOnOrder(detailedworkstats?: Detailedworkstats, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).modifyWorkOnOrder(detailedworkstats, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get notification send stats
     * @param {string} [dd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public ordersDeliveryProposalNotificationstatsGet(dd?: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).ordersDeliveryProposalNotificationstatsGet(dd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process proposal answer
     * @param {string} hashToken 
     * @param {ProposalAnswerBody} [proposalAnswerBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public processProposalAnswer(hashToken: string, proposalAnswerBody?: ProposalAnswerBody, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).processProposalAnswer(hashToken, proposalAnswerBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process proposal answer
     * @param {string} hashToken 
     * @param {string} xTenantID 
     * @param {ProposalAnswerBody} [proposalAnswerBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public processProposalAnswerWithPayload(hashToken: string, xTenantID: string, proposalAnswerBody?: ProposalAnswerBody, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).processProposalAnswerWithPayload(hashToken, xTenantID, proposalAnswerBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove assigned user from orders
     * @param {RemoveUserFromOrdersRequest} [removeUserFromOrdersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public removeUserFromOrders(removeUserFromOrdersRequest?: RemoveUserFromOrdersRequest, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).removeUserFromOrders(removeUserFromOrdersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dd 
     * @param {number} [orderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public sendNotificationForDeliveryDate(dd: string, orderId?: number, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).sendNotificationForDeliveryDate(dd, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary salesMargin log
     * @param {number} orderId 
     * @param {number} sequence 
     * @param {SetSalesMarginLogRequest} [setSalesMarginLogRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public setSalesMarginLog(orderId: number, sequence: number, setSalesMarginLogRequest?: SetSalesMarginLogRequest, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).setSalesMarginLog(orderId, sequence, setSalesMarginLogRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set available hours for a specified year/week
     * @param {number} year 
     * @param {number} week 
     * @param {SetWorkPlanningHoursBody} [setWorkPlanningHoursBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public setWorkPlanningAvailableHours(year: number, week: number, setWorkPlanningHoursBody?: SetWorkPlanningHoursBody, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).setWorkPlanningAvailableHours(year, week, setWorkPlanningHoursBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logs work start time for active user on given order
     * @summary Pick up order
     * @param {string} id 
     * @param {string} [formId] logs work start time for a form related to given order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public startWorkOnOrder(id: string, formId?: string, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).startWorkOnOrder(id, formId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logs end time of work on specified order
     * @summary Release order
     * @param {string} id 
     * @param {string} [formId] logs work stop time for a form related to given order
     * @param {boolean} [allUsers] Apply on all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public stopWorkOnOrder(id: string, formId?: string, allUsers?: boolean, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).stopWorkOnOrder(id, formId, allUsers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create or update a google calendar delivery event
     * @param {CalendarEvent | null} [calendarEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public updateDelivery(calendarEvent?: CalendarEvent | null, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).updateDelivery(calendarEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates given order with the new specification
     * @summary Update order specification
     * @param {string} id 
     * @param {string} formId 
     * @param {OrderDetails} [orderDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public updateOrder(id: string, formId: string, orderDetails?: OrderDetails, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).updateOrder(id, formId, orderDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update order product
     * @param {string} id 
     * @param {number} sequence 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public updateOrderProduct(id: string, sequence: number, body?: object, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).updateOrderProduct(id, sequence, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * order progress update for a specified order / formId
     * @param {string} id 
     * @param {Array<OrderProgressSummary>} [orderProgressSummary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public updateOrderProgress(id: string, orderProgressSummary?: Array<OrderProgressSummary>, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).updateOrderProgress(id, orderProgressSummary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Order Summary
     * @param {string} id 
     * @param {OrderSummary} [orderSummary] 
     * @param {boolean} [cleanupStale] boolean used to force cleanup depending on operation and user role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public updateOrderSummary(id: string, orderSummary?: OrderSummary, cleanupStale?: boolean, options?: RawAxiosRequestConfig) {
        return OrderApiFp(this.configuration).updateOrderSummary(id, cleanupStale, orderSummary, options).then((request) => request(this.axios, this.basePath));
    }
}



